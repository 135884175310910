var Layout = function($){
    var $this = this;
    //

    var template_dir = $('#hid-themeuri').val();
    var ajaxBuffer = {};
    var currentPremiumBannerCampaignObj = null;
    var updatedPremiumBannerImpression = {};

    // settings
    $this.jsPath = template_dir+'/default/public/js/';
    $this.jsMergedPath = template_dir+'/default/public/merged/';
    $this.ajaxPagesPath = template_dir+'/default/inc/';
    //



    // properties
    $this.menu = null;
    $this.ajaxLoader = null;
    $this.debugTools = null;
    $this.ieFixer = null;
    $this.browserDetect = null;
    $this.commentsHandler = null;
    $this.social = null;
    $this.isIElt8 = false;
    $this.isFixedTopBar = false;
    $this.googleBannerLoaded = false;

    $this.init = function(){
        if( $("html.ie.lt8").length ){
            $this.isIElt8 = true;
            dependencies.push({file:'iefixer.js',callback:$this.initIEFixer});
        }

        $("body,html").click($this.bodyClickHandler);
        // load script
        for(var key in dependencies)$this.loadScript(dependencies[key]);
        //
        $this.initListener();
        // init components
        $this.initBarraPortale();
        $this.initializeFixedSocial();
        $this.imageResizer();
        $this.bodyClassesLayout();
        $this.resizeFixedContainer();
        $this.initBackToTop();
        $this.initPrintRequest();
        $this.layoutModifier();
        $this.initSubMenuExpander();
        $this.loadPremiumBanner();

        //
        // init vendors plugin
        $("img.lazy").show().lazyload({
            threshold : 300,
            failure_limit : 50
        });
    };


    $this.loadScript = function(dep){
        if($this.isIElt8) return;
        var path = dep.file == "mergedAjaxMin.js" ? $this.jsMergedPath + dep.file : $this.jsPath + dep.file;
        var callback = dep.callback;
        $.getScript( path )
            .done(function( script, textStatus ) {
                //console.log( path+" caricato con successo! "+textStatus );
                if(callback)callback();
            })
            .fail(function( jqxhr, settings, exception ) {
                //console.error(path+" non caricato!");
            });
    };

    // init Listeners
    $this.initListener = function(){
        $(window).resize($this.onResize);
        $(window).scroll($this.scrollListener);
    };

    // initialization components

    // BARRA PORTALE
    $this.initBarraPortale = function(){
        if( $this.isMobile() ){
            $("body").css("padding-top",0);
            if(!$("#portale").length ){
                var regione = $("#hid-regione").val();
                var dominio = $("#hid-domain").val();
                var blogurl = $("#hid-blogurl").val();
                $("body").prepend('<div id="portale" class="portale"></div>');
                $("#portale").html($("#barra-portale-mobile").html());
            }else{
                $("#portale").show();
            }
            if($this.menu){
                $this.menu.checkTopbar();
                $this.menu.fixedTopbar();
            }
        }else{
            $("body").css("padding-top",45);
            $("#portale").hide();
        }
    };

    // MergedLoader
    $this.initMerged = function(){
        $this.initMenu();
        $this.initAjaxLoader();
        $this.initBrowserDetect();
        $this.initCommentsHandler();
        // $this.initSocial();
        $this.initIEFixer();
    };
    // MENU
    $this.initMenu = function(){
        $this.menu = new TopBarMenu($this,$);
    };

    //AJAX LOADER
    $this.initAjaxLoader = function(){
        $this.ajaxLoader = new AjaxLoader($this,$);
    };

    // DEBUGGER TOOL
    $this.initDebugTools = function(){
        //$this.debugTools = new DebugTools($this);
    };

    $this.initBrowserDetect = function(){
        $this.browserDetect = BrowserDetect;
    };

    $this.initCommentsHandler = function(){
        $this.commentsHandler = new CommentsHandler($this,$);
    };

    // $this.initSocial = function(){
    //     $this.social = new Social($this ,$);
    // };

    // IE FIXER
    $this.initIEFixer = function(){
        if( $this.isIElt8 ){
            $this.ieFixer = new ieFixer($this , $);
            if($this.menu == null)    $this.initMenu();
        }
    };

    //LISTENER DELLO SCROLL
    $this.scrollListener = function(){
        $this.fixedSocial();
    };

    //INIZIALIZZAZIONE PARAMETRI PER LA BARRA DEI SOCIAL NELLA PAGINA ARTICOLO
    $this.initializeFixedSocial = function(){
        //Se non siamo nella pagina dell'articolo singolo tutto questo codice non deve essere eseguito
        if( !$("body.single.single-post").length ){
            return;
        }
        var artSingolo = $(".articolo.singolo");
        setTimeout(function(){
            fixedSocialParameter.offsetTitoloArticolo = artSingolo.find(".titolo").offset().top;
            fixedSocialParameter.offsetArticolo = artSingolo.offset().top;
            fixedSocialParameter.offsetCorpo = artSingolo.find('.corpo').offset().top;
            fixedSocialParameter.heightSocial = $(".social_bar").height();
            fixedSocialParameter.heightCorpo = artSingolo.find('.corpo').height();
            $this.fixedSocial();
        },100);
    };

    $this.initSubMenuExpander = function(){
        if( $(".standard_box.submenu-link-box.single-sub").length ){
            $(".standard_box.submenu-link-box.single-sub").click($this.subMenuExpander);
        }else{
            $(".standard_box.multi-sub .submenu-places").click($this.subMenuExpander);
        }
    };

    $this.subMenuExpander = function(evt){
        var $this = $(this);
        if( $this.hasClass('submenu-places') ){
            var submenuContainer = $this.parent();
            if( submenuContainer.find(".expanded").length > 0 ){
                var otherMenus = submenuContainer.find('.submenu-places').not(this);
                otherMenus.find(".submenu-content").hide();
                otherMenus.find(".arrow-menu").removeClass("fa-angle-up");
                otherMenus.find(".arrow-menu").addClass("fa-angle-down");
                otherMenus.removeClass("expanded");
            }
        }
        var arrow = $(this).find(".arrow-menu");
        $(this).find(".submenu-content").slideToggle(400,function(){
            if( arrow.hasClass("fa-angle-down") ){
                arrow.removeClass("fa-angle-down");
                arrow.addClass("fa-angle-up");
                $this.addClass("expanded");
            }else if( arrow.hasClass("fa-angle-up") ){
                arrow.removeClass("fa-angle-up");
                arrow.addClass("fa-angle-down");
                $this.removeClass("expanded");
            }
        });

    };

    //GESTORE BARRA SOCIAL PAGINA ARTICOLO
    $this.fixedSocial = function(){
        //Se non siamo nella pagina dell'articolo singolo tutto questo codice non deve essere eseguito
        if( !$("body.single.single-post").length ){
            return;
        }
        var scroll = window.scrollY == null ? document.documentElement.scrollTop : window.scrollY;
        var paddingCorpoTesto = parseInt($('.articolo.singolo .corpo').css('padding-top'), 10);
        var altezzaPremiumBanner = fixedSocialParameter.heightPremiumBanner || 0;
        var socialBar = $(".social_bar");
        var bannerH = $(".article-banner").outerHeight(true);
        if( !socialBar.length )  return;
        var initialTop = fixedSocialParameter.offsetTitoloArticolo;

        var startingPosition = scroll+bannerH+paddingCorpoTesto+altezzaPremiumBanner;
        /*socialBar.css({
            top : initialTop,
            position: "fixed"
        });*/
        socialBar.css({
            top : startingPosition,
            position: "absolute",
            left: 0
        });

        if( scroll == 0)return;
        var articoloOffset = fixedSocialParameter.offsetArticolo;
        var altezzaSocial = fixedSocialParameter.heightSocial;
        var offsetCorpo = fixedSocialParameter.offsetCorpo;
        var altezzaCorpo = fixedSocialParameter.heightCorpo +20;//20px di padding-top
        var fineArticolo = offsetCorpo + altezzaCorpo;

        //console.log("scroll : " + scroll +" initialTop : " + initialTop + " fineArticolo : "+fineArticolo);
        if( (scroll+initialTop+altezzaSocial) >= fineArticolo ){
            socialBar.css({
                top : fineArticolo - altezzaSocial - articoloOffset ,
                position: "absolute",
                left: 0
            });

            /*if(socialBar.css("position") != "absolute" ){
                socialBar.css({
                    top : fineArticolo - altezzaSocial - articoloOffset ,
                    position: "absolute",
                    left: 0
                });
            }*/
        }else{
            socialBar.css({
                top : startingPosition,
                position: "absolute",
                left: 0
            });
            /*socialBar.css({
                top : initialTop,
                position: "fixed"
            });*/
        }
    };

    $this.initPrintRequest = function(){
        var beforePrint = function() {
            $('body,html').trigger("scroll");
            $('body,html').scroll();
            var scroll = window.scrollY;
            $(window).scrollTop($(document).height()-$(window).height());
            $(window).scrollTop(scroll);
            console.log('Functionality to run before printing.');
        };
        var afterPrint = function() {
            console.log('Functionality to run after printing');
        };
        if (window.matchMedia) {
            var mediaQueryList = window.matchMedia('print');
            mediaQueryList.addListener(function(mql) {
                if (mql.matches) {
                    beforePrint();
                } else {
                    afterPrint();
                }
            });
        }

        window.onbeforeprint = beforePrint;
        window.onafterprint = afterPrint;
    };

    // RESIZE IMAGES
    $this.imageResizer = function(){
        var aperturaDiv = $(".apertura");
        //setTimeout(function(){   },100);
        if( aperturaDiv.length ){
            var main_cover = $("img.main_cover");
            var contw = aperturaDiv.width();
            var conth = aperturaDiv.height();
            var proporzione = 2.6875;
            var overlay_height = $(".overlay_apertura").height();


            var img = main_cover; // Get my img elem
            var pic_real_width, pic_real_height;
            $("<img/>").attr("src", $(img).attr("src"))
                .load(function() {
                    pic_real_width = this.width;   // Note: $(this).width() will not
                    pic_real_height = this.height; // work for in memory images.

                    //console.log('pic_real_height',pic_real_height);
                    //console.log('pic_real_width',pic_real_width);

                    aperturaDiv.height(  Math.round((contw/proporzione) + overlay_height)    );

                    if( $this.isMobile() ){
                        conth = aperturaDiv.height();
                        var visible_window = conth-overlay_height;
                        var negativeTop = (visible_window - main_cover.height())/2;
                        $(".apertura img.main_cover").css( "top", negativeTop  );
                    }
                });
        }

        //,#singlePage .cover .not(".float_box.box_feeds .feed .box_foto img")
        $(".box_foto img").each(function(){
            if(!$(this).hasClass("lazy") && $(this).parents(".articolo.secondo_piano").length == 0 ) return true;
            var src = $(this).data('original') || $(this).attr("src");
            var $this = this;
            var i = new Image();
            i.onload = function(){


                var w = this.width; // $(this).width();
                var h = this.height; // $(this).height();

                var wp = $($this).parent().width();
                var hp = $($this).parent().height()+30; //+30 per evitare erori di conteggio iniziale con h=0

                var wn,hn;

                //console.log("w:"+w+"; h :"+h+"; wp: "+wp+"; hp : "+hp);
                wn = wp;
                hn = (wn/w)*h;
                if(hn < hp){
                    hn = hp;
                    wn = (hn/h)*w;
                }
                //console.log("wn : "+wn + " ; hn : "+hn);
                $($this).width(wn);
                $($this).height(hn);
                // todo: pensare a come ottimizzare le teste tagliate
                $($this).css({
                    'top': ( (hn-$($this).parent().height())/2)*(-1),
                    'left': ( (wn-wp)/2)*(-1)
                });
            };
            i.src = src;
        });
    };
    //
    // Listeners
    $this.onResize = function(event){
        $this.initBarraPortale();
        $this.imageResizer();
        $this.resizeFixedContainer();
        $this.initializeFixedSocial();
        $this.scrollListener();
        $this.layoutModifier();
        $this.bodyClassesLayout();
        if($this.ieFixer)   $this.ieFixer.ieMediaQuery();
    };


    $this.chiudiPopver = function(e){
        if( !$(".popover").length )return;
        $('[data-toggle="popover"]').each(function () {
            //the 'is' for buttons that trigger popups
            //the 'has' for icons within a button that triggers a popup
            if (!$(this).is(e.target) && $(this).has(e.target).length === 0 && $('.popover').has(e.target).length === 0) {
                $(this).popover('hide');
                $("#topbar li.btmenu").removeClass("topbar_active");
            }
        });
    };

    $this.bodyClickHandler = function(evt){
        $this.chiudiPopver(evt);
    };

    //
    // Fixed container check
    $this.resizeFixedContainer = function(){
        var w = $(window).width();
        if( $(".single_page").length && w>= 1000){
            $(".contenitore").addClass("fix1000");
        }else{
            $(".contenitore").removeClass("fix1000");
        }
    };


    //HANDLER PER IL BACK-TO-TOP
    $this.initBackToTop = function(){
        if(!$this.isMobile()){
            $(".go-top").click(function(evt){
                evt.preventDefault();
                $('html, body').animate({ scrollTop: 0 }, 500);
            });
        }
    };

    // Responsabile della modifica di classi/dimensioni durante il resize
    $this.layoutModifier = function(){
        $this.homePageLayout();
        $this.galleryPageLayout();
        $this.archivePageLayout();
        if( currentPremiumBannerCampaignObj != null ){
            $this.refreshPremiumBanner();
        }
        if( $(".box_temi .temi .ban").length ){
            $this.scaleBannerBoxes(".box_temi .temi .ban .banContainer",".box_temi .temi .tema:first-child",Layout.GOOGLE_BANNER);
        }
        $this.articlePageLayout();
    };

    $this.refreshPremiumBanner = function(){
        var body = $("body");
        var premiumBanner = $(".premium-banner");

        // Attivo lo scaling del banner quando il contenitore scende sotto i 468px
        if( premiumBanner.hasClass("homepage") ){
            $this.scaleBannerBoxes(".premium-banner.homepage",".articoli");
        }else if( premiumBanner.hasClass("article") ){
            //$this.scaleBannerBoxes(".premium-banner.article",".articolo.singolo .corpo");
            $this.scaleBannerBoxes(".premium-banner.article",".articolo.singolo");
        }else if( premiumBanner.hasClass("archive") ) {
            $this.scaleBannerBoxes(".premium-banner.archive", "#archivePage");
        }

        if( !premiumBanner.length || currentPremiumBannerCampaignObj == null ||
            (body.hasClass("lay-wide") && premiumBanner.hasClass("bp880")) ||
            (body.hasClass("lay-desktop") && premiumBanner.hasClass("bp680")) ||
            (body.hasClass("lay-tablet") && premiumBanner.hasClass("bp468")) ||
            (body.hasClass("lay-smart") && premiumBanner.hasClass("bp468")) ){
            return;
        }

        var banner880 = currentPremiumBannerCampaignObj.banner880;
        var banner680 = currentPremiumBannerCampaignObj.banner680;
        var banner468 = currentPremiumBannerCampaignObj.banner468;

        var premiumLink = premiumBanner.find(".premium-banner-link");
        var premiumImg = premiumBanner.find(".premium-banner-img");
        var bannerClasses = ["bp468","bp680","bp880"];
        var remove = bannerClasses.join(" ");
        premiumBanner.removeClass(remove);
        if( premiumBanner.hasClass("homepage") ){
            if($this.isWide() ){
                $this.updatePremiumBannerAttr(premiumBanner,banner880,$this.CLASS_PREMIUM_BANNER_880,premiumLink,premiumImg);
            }else if( $this.isDesktop() ){
                $this.updatePremiumBannerAttr(premiumBanner,banner680,$this.CLASS_PREMIUM_BANNER_680,premiumLink,premiumImg);
            }else{ //if( $this.isMobile || $this.isTablet )
                $this.updatePremiumBannerAttr(premiumBanner,banner468,$this.CLASS_PREMIUM_BANNER_468,premiumLink,premiumImg);
            }
        }else if( premiumBanner.hasClass("article") ){
            if( $this.isWide() || $this.isDesktop() ){
                $this.updatePremiumBannerAttr(premiumBanner,banner680,$this.CLASS_PREMIUM_BANNER_680,premiumLink,premiumImg);
            }else{ //if( $this.isMobile || $this.isTablet )
                $this.updatePremiumBannerAttr(premiumBanner,banner468,$this.CLASS_PREMIUM_BANNER_468,premiumLink,premiumImg);
            }
        }else if( premiumBanner.hasClass("attachment") ){
            if( $this.isWide() || $this.isDesktop() ){
                $this.updatePremiumBannerAttr(premiumBanner,banner680,$this.CLASS_PREMIUM_BANNER_680,premiumLink,premiumImg);
            }else{ //if( $this.isMobile || $this.isTablet )
                $this.updatePremiumBannerAttr(premiumBanner,banner468,$this.CLASS_PREMIUM_BANNER_468,premiumLink,premiumImg);
            }
        }else if( premiumBanner.hasClass("archive") ){
            if( $this.isWide() || $this.isDesktop() ){
                $this.updatePremiumBannerAttr(premiumBanner,banner680,$this.CLASS_PREMIUM_BANNER_680,premiumLink,premiumImg);
            }else{ //if( $this.isMobile || $this.isTablet )
                $this.updatePremiumBannerAttr(premiumBanner,banner468,$this.CLASS_PREMIUM_BANNER_468,premiumLink,premiumImg);
            }
        }
    };

    $this.updatePremiumBannerAttr = function(bannerContainer,banner,sizeClass,link,img){
        bannerContainer.addClass(sizeClass);
        link.attr("href",banner.pb_link);
        link.attr("title",banner.pb_name);
        img.removeAttr("src").attr("src",banner.pb_img_src);
        if( !updatedPremiumBannerImpression[sizeClass] ){
            updatedPremiumBannerImpression[sizeClass] = true;
            $this.wordpressAjax({
                data: {
                    action: "update_premium_banner_impression",
                    bannerFormat:   sizeClass.replace("bp",'')
                },
                callback: function(data){ }
            },'updatedPremiumBannerImpression');
        }
        $this.replaceImg(img.get(0),banner.pb_img_src);
    };

    $this.replaceImg = function(oldImage, newSrc) {
        var img = new Image();
        img.onload = function() {
            var parent = oldImage.parentNode;
            if( parent != null ){
                parent.insertBefore(img, oldImage);
                parent.removeChild(oldImage);
            }
        };
        img.src = newSrc;
        img.className = 'premium-banner-img';
    };

    $this.loadPremiumBanner = function(){
        if( !$(".premium-banner").length ){ return;}
        $this.wordpressAjax({
            data: {
                action: "get_current_campaign"
            },
            callback: function(data){
                currentPremiumBannerCampaignObj = JSON.parse(data);
                $this.refreshPremiumBanner();
                fixedSocialParameter.heightPremiumBanner = $(".articolo.singolo").find('.premium-banner.article').height();
                $this.fixedSocial();
            }
        },"loadPremiumBanner");
    };

    $this.articlePageLayout = function(){
        if( $(".articolo.singolo").hasClass("premiumbanner") ){
            $this.scaleBannerBoxes(".article-banner",".articolo.singolo .corpo");
        }
    };

    //Modifiche layout gallery page - richiamata da $this.layoutModifier
    $this.galleryPageLayout = function(){
        if( $("#gallery-page").length  ){
            var updateGallerySize = setTimeout(function(){
                if($(".ngg-imagebrowser #main-pic-container .tos-slider").length){
                    if( $this.isMobile() ){
                        var slideH = $(".ngg-imagebrowser #main-pic-container .slider-pic > a > img").height();
                        $(".ngg-imagebrowser #main-pic-container").height(slideH);
                    }else{
                        $(".ngg-imagebrowser #main-pic-container").attr('style', '');
                    }
                    clearInterval(updateGallerySize);
                }
            },200);
        }
    };

    $this.archivePageLayout = function(){
        if( $("#archivePage").length ){
            $this.scaleBannerBoxes("#archivePage .watchthisContainer","#archivePage");
        }
    };

    //Modifiche layout home page - richiamata da $this.layoutModifier
    $this.homePageLayout = function(){
        if( $(".contenitore.main").length  ){
            if( $this.isMobile() || $this.getViewport().width < 800){ $this.editTemiClass(true); }else{ $this.editTemiClass(false); }
        }
    };

    $this.improveBannerScaling = function(contWidth,scaleVal){
        var newScaleVal = scaleVal + 0.05;
        if( (468*newScaleVal) < contWidth ){
            //return newScaleVal;
            return $this.improveBannerScaling(contWidth,newScaleVal);
        }
        return scaleVal;
    };

    $this.doScaleBanner = function(banBox,banBoxContainerWidth,scaleVal){
        //console.log('banBox',banBox);
        //console.log('banBoxContainerWidth',banBoxContainerWidth);
        //console.log('scaleVal',scaleVal);
        if( banBoxContainerWidth < 468 ){
            banBox.each(function(){
                //console.log('this box',$(this));
                $(this).css({
                    '-webkit-transform': 'scale('+scaleVal+')',  /* Saf3.1+, Chrome */
                    '-moz-transform': 'scale('+scaleVal+')',  /* FF3.5+ */
                    '-ms-transform': 'scale('+scaleVal+')',  /* IE9 */
                    '-o-transform': 'scale('+scaleVal+')',  /* Opera 10.5+ */
                    'transform': 'scale('+scaleVal+')',
                    '-ms-filter': "progid:DXImageTransform.Microsoft.Matrix(M11=0.5, M12=0, M21=0, M22=0.5, SizingMethod='auto expand')",
                    'filter': "progid:DXImageTransform.Microsoft.Matrix("+
                        "M11=0.5,"+
                        "M12=0,"+
                        "M21=0,"+
                        "M22=0.5,"+
                        "SizingMethod='auto expand')",
                    'transform-origin': '0 0',
                    '-webkit-transform-origin': '0 0',
                    '-moz-transform-origin': '0 0',
                    '-ms-transform-origin': '0 0',
                    '-o-transform-origin': '0 0'
                    //Con center il banner risulta sicuramente + allineato al centro ma delle volte tocca i bordi del contenitore
                    //'transform-origin': 'center',
                    //'-webkit-transform-origin': 'center',
                    //'-moz-transform-origin': 'center',
                    //'-ms-transform-origin': 'center',
                    //'-o-transform-origin': 'center'
                });
            });
        }else{
            banBox.each(function(){
                $(this).css({
                    '-webkit-transform': 'scale(1)',  /* Saf3.1+, Chrome */
                    '-moz-transform': 'scale(1)',  /* FF3.5+ */
                    '-ms-transform': 'scale(1)',  /* IE9 */
                    '-o-transform': 'scale(1)',  /* Opera 10.5+ */
                    'transform': 'scale(1)',
                    '-ms-filter': 'none',
                    'transform-origin': 'initial',
                    '-webkit-transform-origin': 'initial',
                    '-moz-transform-origin': 'initial',
                    '-ms-transform-origin': 'initial',
                    '-o-transform-origin': 'initial'
                });
            });
        }
    };

    $this.scaleBannerBoxes = function(bannerBoxSelector,bannerBoxContainerSelector,google){
        google = google || false;
        var banBox = $(bannerBoxSelector);
        if( banBox.length ){
            var banBoxContainerWidth = $(bannerBoxContainerSelector).width();
            var scaleVal = Math.floor( (banBoxContainerWidth/468) * 20 )/20;
            //console.log('old-scaleVal',scaleVal);
            scaleVal = $this.improveBannerScaling(banBoxContainerWidth,scaleVal);

            //console.log('bannerBoxSelector',bannerBoxSelector);
            //console.log('bannerBoxContainerSelector',bannerBoxContainerSelector);
            //console.log('banBoxContainerWidth',banBoxContainerWidth);
            //console.log('new-scaleVal',scaleVal);
            //console.log('$this.googleBannerLoaded',$this.googleBannerLoaded );

            if( google && !$this.googleBannerLoaded ){
                var gAdsInterval = setInterval(function(){
                    if( banBox.find('iframe').length ){
                        clearInterval(gAdsInterval);
                        $this.googleBannerLoaded = true;
                        $this.doScaleBanner(banBox,banBoxContainerWidth,scaleVal);
                    }
                },100);
            }else{
                $this.doScaleBanner(banBox,banBoxContainerWidth,scaleVal);
            }
        }
    };

    $this.editTemiClass = function(add){
        $(".float_box.box_temi .nome-cat").each(function(){
            var classList =  $(this).parent().attr('class').split(/\s+/);
            classList = classList.splice(1, 2);
            for(cl in classList){
                if(add){
                    $(this).addClass(classList[cl]);
                }else{
                    $(this).removeClass(classList[cl]);
                }
            }
        });
        if( add ){
            $(".float_box.box_feeds .territorio").addClass('defaultcat left');
        }else{
            $(".float_box.box_feeds .territorio").removeClass('defaultcat left');
        }
    };

    // private settings
    var dependencies = [
        {file:'mergedAjaxMin.js',callback:$this.initMerged}
        /*{file:'menu.js',callback:$this.initMenu},
        {file:'ajaxloader.js',callback:$this.initAjaxLoader},
        {file:'commenti.js',callback:$this.initCommentsHandler},
        {file:'iefixer.js',callback:$this.initIEFixer},
        //{file:'debug.js',callback:$this.initDebugTools},
        {file:'browserdetect.js',callback:$this.initBrowserDetect},
        {file:'social.js',callback:$this.initSocial}*/
    ];

    /* Contenitore per le misurazioni della barra social così
     *  da calcolare tutti i parametri una sola volta
     */
    var fixedSocialParameter = {
        "offsetTitoloArticolo" : null,
        "offsetArticolo" : null,
        "offsetCorpo" : null,
        "heightSocial" : null,
        "heightCorpo" : null
    };

    $this.checkNewsletterWidgetData = function(form){
        var re = /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-]{1,})+\.)+([a-zA-Z0-9]{2,})+$/;
        if (!re.test(form.elements["ne"].value)) {
            alert("L\'indirizzo email non è corretto");
            return false;
        }
        if (form.elements["nn"] && (form.elements["nn"].value == "" || form.elements["nn"].value == form.elements["nn"].defaultValue)) {
            alert("Il nome non è corretto");
            return false;
        }
        if (form.elements["ny"] && !form.elements["ny"].checked) {
            alert("Devi accettare le regole di riservatezza");
            return false;
        }
        return true;
    };
    $this.isPhone = function(){
        return  $this.getViewport().width < 479;
    };

    // Browser Sizes Detection
    $this.isMobile = function(){
        return  $this.getViewport().width < 768;
    };

    $this.isLimbo = function () {
        return  $this.getViewport().width >= 768 && $this.getViewport().width <= 799;
    };

    $this.isTablet = function () {
        return  $this.getViewport().width >= 768 && $this.getViewport().width <= 999;
    };

    $this.isDesktop = function () {
        return  $this.getViewport().width >= 1000 && $this.getViewport().width <= 1200;
    };

    $this.isWide = function () {
        return  $this.getViewport().width > 1201;
    };

    $this.bodyClassesLayout = function(){
        var layoutClasses = ['lay-phone','lay-smart','lay-limbo','lay-tablet','lay-desktop','lay-wide'];
        var remove = layoutClasses.join(" ");
        var body = $("body");
        body.removeClass(remove);
        if( $this.isWide() ){
            body.addClass('lay-wide');
        }else if( $this.isDesktop() ){
            body.addClass('lay-desktop');
        }else if( $this.isTablet() ){
            body.addClass('lay-tablet');
            if( $this.isLimbo() ){
                body.addClass('lay-limbo');
            }
        }else if( $this.isMobile() ){
            body.addClass('lay-smart');
            if( $this.isPhone() ){
                body.addClass('lay-phone');
            }
        }
    };

    $this.wordpressAjax = function(payload,id){
        if( ajaxBuffer[id] == undefined || ajaxBuffer[id] == null ){
            ajaxBuffer[id] = [];
        }
        for(var reqId in ajaxBuffer ){
            if( ajaxBuffer.hasOwnProperty(reqId) && ajaxBuffer[reqId]  ){
                var reqBuffer = ajaxBuffer[reqId];
                for(var reqIdx in reqBuffer  ){
                    if( reqBuffer.hasOwnProperty(reqIdx) && reqBuffer[reqIdx] ){
                        reqBuffer[reqIdx].abort();
                    }
                }
            }
        }

        var ajaxUrl = "/wp-admin/admin-ajax.php";
        var request = $.ajax({
            url: ajaxUrl,
            cache:false,
            type: "POST",
            data: payload.data,
            dataType:'html',
            error: function(MLHttpRequest, textStatus, errorThrown){
                //alert("There was an error: " + errorThrown);
                console.log('Wordpress Ajax payload',payload);
            },
            success: function ( data ) {
                ajaxBuffer[id].pop();
                //console.log('wordpressAjax - payload|response',payload,data);
                if(data == "undefined"){  return;  }
                payload.callback(data);
            }
        });
        ajaxBuffer[id].push(request);
    };

    //GET VIWPORT TO ACT ACCORDING TO MEDIA QUERY
    $this.getViewport = function(){
        var e = window, a = 'inner';
        if (!('innerWidth' in window )) {
            a = 'client';
            e = document.documentElement || document.body;
        }
        return { width : e[ a+'Width' ] , height : e[ a+'Height' ] };
    };
};

Layout.prototype.GOOGLE_BANNER = true;
Layout.prototype.CLASS_PREMIUM_BANNER_468 = "bp468";
Layout.prototype.CLASS_PREMIUM_BANNER_680 = "bp680";
Layout.prototype.CLASS_PREMIUM_BANNER_880 = "bp880";

var site = new Layout(jQuery);
jQuery(document).ready(site.init);

window.onload = function(){
    jQuery("img.lazy").trigger("scroll");
};



